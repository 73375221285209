import isJsonString from './isJsonString';
import isSessionStorageAvailable from './sessionStorageAvailable';

const getSessionStorageItem = <T>(key: string, defaultValue: T): T => {
  if (!isSessionStorageAvailable) return defaultValue;

  const partnerKey = `${process.env.NEXT_PUBLIC_PARTNER}_${key}`;
  const stringValue = sessionStorage.getItem(partnerKey);

  if (stringValue && isJsonString(stringValue)) return JSON.parse(stringValue);
  return defaultValue;
};

export default getSessionStorageItem;
