'use client';

import { useContext } from 'react';

import HistoryContext from 'context/History';

import Icon from '../Icon';

import styles from './styles.module.scss';

function Back() {
  const { back: goBack, hasHistory } = useContext(HistoryContext);

  return hasHistory ? (
    <div className={styles.container}>
      <a className={styles.back} data-cy="goBack" onClick={goBack}>
        <Icon name="back" />
      </a>
    </div>
  ) : null;
}

export default Back;
