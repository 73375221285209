'use client';

import React, { useState } from 'react';

import DevFeatures from '@/components/DevMenu/DevABFeatures';
import DevDeviceInfo from '@/components/DevMenu/DevDeviceInfo';
import DevLogs from '@/components/DevMenu/DevLogs';
import DevUtils from '@/components/DevMenu/DevUtils';
import SubTitle from '@/components/SubTitle';
import Feature from '@/types/feature';

import styles from './styles.module.scss';

export enum DevTabs {
  UTILS = 'utils',
  AB_FEATURES = 'abFeatures',
  LOGS = 'logs',
  DEVICE_INFO = 'deviceInfo',
}

function DevContent({ features }: { features?: Feature[] }) {
  const [tab, setTab] = useState(DevTabs.UTILS);

  const tabWrapper = (tabChild: React.ReactNode, tabName: DevTabs) => (
    <div
      id={`tabPanel-${tabName}`}
      hidden={tab !== tabName}
      role="tabpanel"
      aria-labelledby={`tab-${tabName}`}
    >
      {tabChild}
    </div>
  );

  return (
    <section className={styles.wrapper}>
      <SubTitle>Dev Menu</SubTitle>
      <div role="tablist">
        <button
          type="button"
          id={`tab-${DevTabs.UTILS}`}
          role="tab"
          aria-controls={`tabPanel-${DevTabs.UTILS}`}
          aria-selected={tab === DevTabs.UTILS}
          onClick={() => setTab(DevTabs.UTILS)}
        >
          Utils
        </button>
        <button
          type="button"
          id={`tab-${DevTabs.AB_FEATURES}`}
          role="tab"
          aria-controls={`tabPanel-${DevTabs.AB_FEATURES}`}
          aria-selected={tab === DevTabs.AB_FEATURES}
          onClick={() => setTab(DevTabs.AB_FEATURES)}
        >
          AB Features
        </button>
        <button
          type="button"
          id={`tab-${DevTabs.LOGS}`}
          role="tab"
          aria-controls={`tabPanel-${DevTabs.LOGS}`}
          aria-selected={tab === DevTabs.LOGS}
          onClick={() => setTab(DevTabs.LOGS)}
        >
          Logs
        </button>
        <button
          type="button"
          id={`tab-${DevTabs.DEVICE_INFO}`}
          role="tab"
          aria-controls={`tabPanel-${DevTabs.DEVICE_INFO}`}
          aria-selected={tab === DevTabs.DEVICE_INFO}
          onClick={() => setTab(DevTabs.DEVICE_INFO)}
        >
          Device Info
        </button>
      </div>
      <div className="tab-panels">
        {tabWrapper(<DevUtils />, DevTabs.UTILS)}
        {tabWrapper(<DevFeatures features={features} />, DevTabs.AB_FEATURES)}
        {tabWrapper(<DevLogs />, DevTabs.LOGS)}
        {tabWrapper(<DevDeviceInfo />, DevTabs.DEVICE_INFO)}
      </div>
    </section>
  );
}

export default DevContent;
