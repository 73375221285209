import Button from '@/components/Button';

import Icon from '../Icon';
import { Dialog, DialogContent, DialogTrigger } from '../Modal';

import DevContent from './DevContent';

import styles from './styles.module.scss';

function Content() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className={styles.openButton}>
          <Button type="button" theme="transparent">
            <div className={styles.openButtonWrapper}>
              <Icon name="terminal" />
            </div>
          </Button>
        </div>
      </DialogTrigger>
      <DialogContent size="fullscreen">
        <DevContent />
      </DialogContent>
    </Dialog>
  );
}

export default Content;
