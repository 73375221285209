'use client';

import Button from '@/components/Button';
import copyToClipboard from '@/utils/copyToClipboard';

import Icon from '../Icon';

import styles from './styles.module.scss';

interface Props {
  text: string;
}

function CopyToClipboard({ text }: Props) {
  return (
    <Button
      type="button"
      callback={() => copyToClipboard(text)}
      theme="transparent"
    >
      <div className={styles.iconWrapper}>
        <Icon name="docOnDoc" />
      </div>
    </Button>
  );
}

export default CopyToClipboard;
