'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import Icon from '@/components/Icon';
import { DISPLAY_MAX_CART_ITEMS_ICON } from '@/constants';
import useCartStore from '@/stores/Cart/CartStore';
import getNumberItems from 'utils/order/getNumberItems';

import styles from './styles.module.scss';

function CartItemIcon() {
  const cart = useCartStore((store) => store.items);

  const numberOfItems = getNumberItems(cart);
  const pathname = usePathname();

  const DISABLED_PATH_CART_ICON = [`/refund`];

  return !DISABLED_PATH_CART_ICON.includes(pathname) ? (
    <Link
      href="/cart"
      className={styles.buttonCart}
      data-testid="cart-items-icon"
      aria-label="See items in your cart."
    >
      {numberOfItems !== 0 && (
        <span className={styles.numberItems} data-testid="cart-items-count">
          {numberOfItems > DISPLAY_MAX_CART_ITEMS_ICON ? (
            <span>
              {DISPLAY_MAX_CART_ITEMS_ICON}
              <sup>+</sup>
            </span>
          ) : (
            numberOfItems
          )}
        </span>
      )}

      <div className={styles.iconContainer}>
        <Icon name="cartIcon" />
      </div>
    </Link>
  ) : null;
}

export default CartItemIcon;
