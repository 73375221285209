import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  tag?: string;
  margins?: 'none' | 'small' | 'medium' | 'large' | 'default';
  hasTile?: boolean;
}

function SubTitle({
  children,
  hasTile = true,
  tag = 'h3',
  margins = 'default',
}: PropsWithChildren<Props>) {
  if (tag === 'h3')
    return (
      <h3
        className={clsx(
          styles.subTitle,
          styles[`margins_${margins}`],
          hasTile && styles.hasTile,
        )}
      >
        {children}
      </h3>
    );
  if (tag === 'h4')
    return (
      <h4
        className={clsx(
          styles.subTitle,
          styles[`margins_${margins}`],
          hasTile && styles.hasTile,
        )}
      >
        {children}
      </h4>
    );
  return null;
}

export default SubTitle;
