import { ResponseError } from '../types';

export default function throwError<T extends object>(
  data: T | ResponseError,
): data is ResponseError {
  if ('error' in data) {
    const message = `Catched error => status: "${data.code}, error: "${data.error}", message:"${data.message}"`;
    throw new Error(message);
  }
  return false;
}
