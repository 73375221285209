'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import Icon from '@/components/Icon';
import { sendLog } from '@/services/logs';

import styles from './styles.module.scss';

const AUTHORIZED_PATHS = ['/cart', '/preorderFeedback'];

function FeedbackButton() {
  const pathname = usePathname();

  const isVisible = AUTHORIZED_PATHS.includes(pathname);

  return isVisible ? (
    <div className={styles.container}>
      <Link
        data-testid="preorder-feedback-link"
        href="/preorderFeedback"
        className={styles.button}
        onClick={() => sendLog('ui_catalog_feedbackButtonTapped')}
        aria-label="Give us feedback"
      >
        <Icon name="feedback" />
      </Link>
    </div>
  ) : null;
}

export default FeedbackButton;
