'use client';

import { Fragment } from 'react';

import CopyToClipboard from '@/components/CopyToClipboard';

import styles from './styles.module.scss';

function DevDeviceInfo() {
  const deviceInfo = [
    { name: 'User Agent', value: navigator.userAgent },
    { name: 'Screen Width', value: window.screen.width.toString() },
    { name: 'Screen Height', value: window.screen.height.toString() },
    { name: 'Pixel Ratio', value: window.devicePixelRatio.toString() },
    { name: 'Language', value: navigator.language },
    { name: 'Platform', value: navigator.platform },
  ];

  return (
    <article>
      <div className={styles.grid}>
        {deviceInfo.map((info) => (
          <Fragment key={info.name}>
            <div className={styles.info}>
              <b>{info.name}</b>
              <span>{info.value}</span>
            </div>
            <CopyToClipboard text={info.value} />
          </Fragment>
        ))}
      </div>
    </article>
  );
}

export default DevDeviceInfo;
