import { CartItem } from '@/stores/Cart/types';

const getNumberItems = (cart: CartItem[] | undefined = []): number => {
  let total = 0;

  cart.forEach((cartItem) => {
    total += cartItem.quantity;
  });

  return total;
};

export default getNumberItems;
