'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import Icon from '@/components/Icon';
import { getOrders } from '@/services/orders/client';
import { HistoryOrder, OrderStatus } from '@/types/order';

import styles from './styles.module.scss';

function OrderHistoryButton() {
  const [orders, setOrders] = useState<HistoryOrder[]>([]);
  const pathname = usePathname();

  //  Match /orders and /orders/* and /
  const regexDisplayIcon = /(^\/orders|^\/$)/;
  const isVisible = regexDisplayIcon.test(pathname);

  useEffect(() => {
    if (!isVisible) return;
    (async () => {
      const fetchOrders = await getOrders();
      setOrders(fetchOrders);
    })();
  }, [isVisible]);

  const orderStatus = [
    OrderStatus.READY_FOR_PICKUP,
    OrderStatus.PERSISTED,
    OrderStatus.WAITING_FOR_PAYMENT,
    OrderStatus.POSTED,
  ];

  const numberOfItems = orders.filter((order) =>
    orderStatus.includes(order.status),
  ).length;

  return isVisible ? (
    <Link
      href="/orders"
      className={styles.container}
      aria-label="Order history"
      data-testid="order-history-button"
    >
      {numberOfItems !== 0 && (
        <span className={styles.numberItems} data-testid="order-history-count">
          {numberOfItems}
        </span>
      )}
      <div className={styles.iconContainer}>
        <Icon name="history" />
      </div>
    </Link>
  ) : null;
}

export default OrderHistoryButton;
