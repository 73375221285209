'use client';

import { Fragment } from 'react';

import CopyToClipboard from '@/components/CopyToClipboard';
import Feature from '@/types/feature';

import styles from './styles.module.scss';

function DevABFeatures({ features }: { features?: Feature[] }) {
  return (
    <article>
      <div className={styles.grid}>
        {features?.map((feature) => (
          <Fragment key={feature.id}>
            <div className={styles.feature}>
              <b>{feature.id}</b>
              <span>{feature.variant.id}</span>
            </div>
            <CopyToClipboard text={feature.variant.id} />
          </Fragment>
        ))}
      </div>
    </article>
  );
}

export default DevABFeatures;
