'use client';

import { Environments } from '@/constants';

import Button from './Button';

function DevMenu() {
  if (process.env.NEXT_PUBLIC_GCP_ENV === Environments.PRODUCTION) return null;
  return <Button />;
}

export default DevMenu;
