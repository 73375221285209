import { Draft } from 'immer';
import Cookies from 'js-cookie';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { COOKIE_DEVICE_ID } from '@/constants';
import generateOrderId from '@/utils/order/generateOrderId';

import { Order, OrderStatus } from './types';

const useOrderStore = create<Order>()(
  persist(
    // _set is needed to type zustand correctly
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    immer((_set) => ({
      id: generateOrderId(),
      deviceId: Cookies.get(COOKIE_DEVICE_ID)!,
      deliveryMethod: 'PICKUP',
      status: OrderStatus.Start,
    })),
    {
      name: `${process.env.NEXT_PUBLIC_PARTNER}_order_store`,
    },
  ),
);

export const getOrderState = useOrderStore.getState;

export const setOrder = (updater: (state: Draft<Order>) => void) =>
  useOrderStore.setState(updater);

export const setNewOrderId = () =>
  useOrderStore.setState((draft) => {
    draft.id = generateOrderId();
  });

export const resetOrder = () =>
  useOrderStore.setState((draft) => {
    draft.id = generateOrderId();
    draft.status = OrderStatus.Start;
    draft.coupon = undefined;
  });

export default useOrderStore;
