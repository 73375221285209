const fallback = (text: string) => {
  const isIos = navigator.userAgent.match(/ipad|iphone/i);
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // ios will zoom in on the input if the font-size is < 16px
  textArea.style.fontSize = '20px';
  document.body.appendChild(textArea);

  if (isIos) {
    const range = document.createRange();
    range.selectNodeContents(textArea);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }

  document.execCommand('Copy');
  textArea.remove();
};

const copyToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallback(text);
    return;
  }

  navigator.clipboard.writeText(text);
};

export default copyToClipboard;
