'use client';

import { FormEvent, Fragment, useEffect, useState } from 'react';

import Button from '@/components/Button';
import CopyToClipboard from '@/components/CopyToClipboard';
import { LOCAL_LOGS_KEY } from '@/constants';
import { Log } from '@/services/logs/types';
import getSessionStorageItem from '@/utils/sessionStorage/getSessionStorageItem';

import styles from './styles.module.scss';

function DevLogs() {
  const getNewLogs = (): Log[] | undefined =>
    getSessionStorageItem<Log[] | undefined>(
      LOCAL_LOGS_KEY,
      undefined,
    )?.reverse();

  const [logs, setLogs] = useState<Log[] | undefined>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    setLogs(getNewLogs());
  }, []);

  const logsFilter = logs?.filter((log) =>
    log.event.toLowerCase().includes(search.toLowerCase()),
  );

  const onFilter = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const searchValue = event.currentTarget['search-logs'].value;
    setSearch(searchValue);
  };

  return (
    <article>
      <div className={styles.actionsWrapper}>
        <div>
          <form onSubmit={onFilter}>
            <input type="search" id="search-logs" name="search-logs" />
            <Button theme="secondary" type="submit">
              Search
            </Button>
          </form>
        </div>
        <CopyToClipboard text={JSON.stringify(logsFilter, null, '\t')} />
      </div>
      <Button theme="noStyle" type="button" callback={getNewLogs}>
        Refresh
      </Button>
      <p className={styles.resultsCount}>{logsFilter?.length} results</p>
      <div className={styles.grid}>
        {logsFilter?.map((log, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={log.timestamp + log.event + index}>
            <details>
              <summary>
                <b>
                  {log.event} -{' '}
                  {new Date(log.timestamp || Date.now()).toLocaleTimeString()}
                </b>
              </summary>
              <CopyToClipboard text={JSON.stringify(log, null, '\t')} />
              <div>
                <pre className={styles.pre}>
                  {JSON.stringify(log.value, null, '\t')}
                </pre>
              </div>
            </details>
          </Fragment>
        ))}
      </div>
    </article>
  );
}

export default DevLogs;
